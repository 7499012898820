/* eslint-disable no-throw-literal */
import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { Modal, Spin } from 'antd';
import { Table, DatePicker, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './App.css';
import { message } from 'antd';
import { Typography, Divider } from 'antd';
import { Space } from 'antd';
import { ShakeOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { Search } = Input;

const { Title, Paragraph, Text, Link } = Typography;

type State = {
}

class Main extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  calcColor = function (pos: number) {
    if (pos < 10) {
      return "#75ff79"
    } else if (pos < 20) {
      return "#91E478"
    } else if (pos < 30) {
      return "#B9BC77"
    } else if (pos < 40) {
      return "#D6A176"
    } else if (pos < 100) {
      return "#FF7875"
    } else {
      return "#737373"
    }
  }

  safeWordsEncode(text: string): string {
    let word = text.split("")
    let wordList: string[] = []
    for (let item of word) {
      const code = item.charCodeAt(0)
      const binary = code.toString(2).replaceAll("1", "赞").replaceAll("0", " ")

      wordList.push(binary)
    }
    const safeWords = "新中国" + wordList.join("中国")
    return safeWords
  }
  safeWordsDecode(text: string): string {
    if (!text.startsWith("新中国")) {
      throw "无效字符串"
    }
    text = text.replace("新中国", "")

    text = text.replaceAll("赞", "1")
    text = text.replaceAll(" ", "0")

    let word = text.split("中国")
    console.log('word', word)

    let wordList: number[] = []
    for (let item of word) {
      const dec = parseInt(item, 2)
      wordList.push(dec)
    }
    const oWords = String.fromCharCode(...wordList)
    return oWords
  }
  shortWord(text: string) {
    text = text.replaceAll("新中国", "0")
    text = text.replaceAll("赞", "1")
    text = text.replaceAll(" ", "2")
    text = text.replaceAll("中国", "3")
    return text
  }
  originalWord(text: string) {
    text = text.replaceAll("0", "新中国")
    text = text.replaceAll("1", "赞")
    text = text.replaceAll("2", " ")
    text = text.replaceAll("3", "中国")
    return text
  }
  newWords(str: string) {
    const safeWords = this.safeWordsEncode(str)
    const safeWordsde = this.safeWordsDecode(safeWords)
    console.log("safeWords", safeWords)
    // const uri = encodeURIComponent(safeWords)
    const uri = this.shortWord(safeWords)
    document.location.search = `?uri=${uri}`

  }
  decode(original: string) {
    const originalOriginal = this.safeWordsDecode(original)
    console.log("original", original)

    const originalOriginalReplaceAll = originalOriginal.replaceAll(" ", "&nbsp;")
    console.log("originalOriginalReplaceAll", originalOriginalReplaceAll)
    Modal.confirm({
      title: '都说了不要点😅',
      icon: <ShakeOutlined />,
      content: originalOriginalReplaceAll,
      okText: "我也来生成",
      cancelButtonProps: {
        style: { display: "none" }
      },
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    })
  }
  componentDidMount() {
    let Fthis = this
    const searchParams = new URL(document.location.href).searchParams
    const uri = searchParams.get("uri")
    if (uri && uri.length > 1) {
      // const original = this.originalWord(uri).replaceAll(" ","&nbsp;")
      const original = this.originalWord(uri)

      // const originalOriginal = this.safeWordsDecode(original)
      console.log("componentDidMount original", original)




      Modal.confirm({
        // title: '有人给你分享了一段赞美',
        title: '可以在中国互联网上分享的版本',
        icon: <ExclamationCircleOutlined />,
        content: <div style={{ whiteSpace: "break-spaces" }}>{original}</div>,
        okText: "我也来生成",
        cancelText: "不要点",
        cancelButtonProps: {
          className: "ant-btn-dangerous"
        },
        onOk() {
          console.log('OK');
        },
        onCancel() {
          Fthis.decode(original)
        },
      })
    }
  }
  render() {


    return (
      <div className="App" style={{ overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "100%", padding: 24, maxWidth: 600 }}>
          <Row>
            <Col span={24}>
              <Title style={{ textDecoration: "line-through" }}>
                <a href="./">
                  {"赞赞新.中国"}
                </a>
              </Title>
            </Col>
            <Col span={24} style={{ marginBottom: 24 }}>
              <Search
                placeholder={"输入内心想法"}
                enterButton="生成分享"
                onSearch={this.newWords.bind(this)}
                style={{ width: "100%" }} />
            </Col>

            <Col span={24} style={{ marginBottom: 24 }}>
              <Search
                placeholder={"输入赞美之词"}
                enterButton="请勿点击"
                onSearch={this.decode.bind(this)}
                style={{ width: "100%" }} />
            </Col>


          </Row>
        </div>
      </div>
    );

  }
}


export default Main;
